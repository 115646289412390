<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="牌桌查询" />
      <div class="pagepadding">
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="searchDialog = true">筛选设置</el-button>
        <el-button
          size="small"
          type="warning"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="newDialog = true">新增/修改牌桌</el-button>
        <div v-loading="loading">
          <el-table
            :data="tableData"
            :row-key="row => row.id"
            style="width: 100%">
            <el-table-column label="唯一ID" prop="id"> </el-table-column>
            <el-table-column label="名字" prop="name"> </el-table-column>
            <el-table-column label="图标url" prop="logourl"> </el-table-column>
            <el-table-column label="图片url" prop="picurl"> </el-table-column>
            <el-table-column label="备注" prop="tip"> </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 30px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="params.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="allamount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="搜索内容" :visible.sync="searchDialog">
      <div v-for="(search, index1) in searchNameList" :key="index1">
        <el-input placeholder="请输入内容" v-model="searchObj[search.label]" style="margin:5px;">
          <template slot="prepend">{{search.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="searchDialog = false">取 消</el-button>
        <el-button type="primary" @click="search()">搜 索</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增/修改牌桌" :visible.sync="newDialog" v-loading="newLoading">
      <div v-for="(user, index2) in userNameList" :key="index2">
        <el-input placeholder="请输入内容" v-model="userInfoObj[user.label]" style="margin:5px;" 
          :show-password="(user.label == 'password' || user.label == 'confirmPassword') ? true : false">
          <template slot="prepend">{{user.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newDialog = false">取 消</el-button>
        <el-button type="primary" @click="newUser()">新增/修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      params: {
        page: 1,
        pagesize: 10
      },
      tableData: [],
      allamount: 0,
      loading: false,
      searchObj: {},
      searchNameList: [],
      searchDialog: false,
      newLoading: false,
      userInfoObj: {},
      userNameList: [],
      newDialog: false,
    };
  },
  async mounted() {
    this.searchNameList = [];
    this.searchNameList.push({name: '名字',label: 'name'});
    this.userNameList = [];
    this.userNameList.push({name: '唯一id，不填则新增',label: 'id'});
    this.userNameList.push({name: '名称',label: 'name'});
    this.userNameList.push({name: '图标url',label: 'logourl'});
    this.userNameList.push({name: '图片url',label: 'picurl'});
    this.userNameList.push({name: '备注',label: 'tip'});
    await this.getList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pagesize = val;
      this.params.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.page = val;
      this.getList();
    },
    search(){
      this.searchDialog = false;
      this.params.page = 1;
      this.getList();
    },
    async getList(){
      try{
        this.loading = true;
        var postbody = {};
        if(this.params.page >= 1){
          postbody.offset = (this.params.page - 1) * this.params.pagesize;
          postbody.limit = this.params.pagesize;
        } 
        postbody.searchObj = this.searchObj;
        const { result } = await api.post('/searchTable', postbody);
        console.log(result);
        this.tableData = result.rows;
        this.allamount = result.count;
        this.loading = false;
      }catch(e){
        console.error(e);
        this.loading = false;
      }
    },
    async newUser(){
      try{
        this.newLoading = true;
        const result = await api.post('/addTable', this.userInfoObj);
        console.log(result);
        this.$message.success('新增/修改牌桌成功');
        this.userInfoObj = {};
        this.newDialog = false;
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.pagepadding {
  padding: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.overhide{
  width: 144px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
